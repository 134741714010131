<template>
  <div class="container">
    <iframe :src="src" frameborder="0"></iframe>
  </div>
</template>

<script>
  export default {
    name: "outWindowBlank",
    data() {
      return {
        // src:''
        src:decodeURI(this.$route.query.src)
      }
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    methods: {}
  }
</script>

<style scoped lang="scss">
  iframe{
    width: 100%;
    height: 100vh;
  }

</style>
